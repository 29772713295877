export const PAGE_SIZE = 10;

export const CORRECT_MATCH_OPTIONS = [
  {
    id: 1,
    name: "Correct",
  },
  {
    id: 2,
    name: "Incorrect",
  },
  {
    id: 3,
    name: "Unknown",
  },
];

export const PERSONAL_CONVERSATION_OPTIONS = [
  {
    id: 1,
    name: "Personal",
  },
  {
    id: 2,
    name: "Not Personal",
  },
];

export const DO_NOT_MESSAGE_OPTIONS = [
  {
    id: 1,
    name: "Do Not Message",
  },
  {
    id: 2,
    name: "Can Message",
  },
];
