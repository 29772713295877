import { Boxes, House, Inbox, Send, SwatchBook } from "lucide-react";

export const navigation = [
  { name: "Overview", path: "/analytics", icon: House },
  {
    name: "Conversations",
    path: "/conversations",
    icon: Inbox,
  },
] as const;

export const orgName = "MeetMore";
