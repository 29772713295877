import { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Link } from "react-router-dom";
import { sendPasswordReset } from "../../../../shared/infrastructure/firebase";
import Logo from "../../../../shared/assets/logo.svg";
import { ForgotPasswordFormValues } from "../types";
import { forgotPasswordValidationSchema } from "../validation";
import ErrorMessage from "../../../../shared/components/errorMessage/ErrorMessage";
import Spinner from "../../../../shared/components/spinner/Spinner";

const ForgotPassword = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ForgotPasswordFormValues>({
    resolver: yupResolver(forgotPasswordValidationSchema),
  });

  const [loading, setLoading] = useState(false);

  const onSubmit = async ({ email }: ForgotPasswordFormValues) => {
    setLoading(true);
    await sendPasswordReset({ email });
    setLoading(false);
  };
  return (
    <div className="min-h-screen flex-wrap flex flex-col items-center justify-center  py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8">
        <div>
          <img className="mx-auto h-12 w-auto" src={Logo} alt="Meetmore" />
          <h2 className="mt-6 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
            Reset your password
          </h2>{" "}
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
              <div>
                <label htmlFor={"email"} className="block text-sm font-medium text-gray-700">
                  Email address
                </label>
                <div className="mt-1">
                  <input
                    {...register("email")}
                    placeholder="Email address"
                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm"
                  />
                  {errors.email && <ErrorMessage>{errors.email.message}</ErrorMessage>}
                </div>
              </div>

              <div>
                <button
                  disabled={loading ? true : false}
                  type="submit"
                  className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-orange-600 hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500"
                >
                  <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                    {loading ? (
                      <Spinner />
                    ) : (
                      <svg
                        className="h-5 w-5 text-orange-500 group-hover:text-orange-400"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    )}
                  </span>
                  {loading ? "Loading" : "Send Password Reset Email"}
                </button>
              </div>
              <p className="mt-5 text-center text-sm text-gray-500">
                {" "}
                <Link
                  to={"./"}
                  className="font-semibold leading-6 text-orange-600 hover:text-orange-500"
                >
                  Try signing in again
                </Link>
              </p>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
