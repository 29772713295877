import { useLazyQuery } from "@apollo/client";
import { GetCampaignLeadProfileByIdQuery } from "../../../../infrastructure/graphql/core/generatedTypes";
import { getCampaignLeadProfileById } from "../queries/getCampaignLeadProfileById";

export type UseCampaignLeadProfileById = ReturnType<typeof useCampaignLeadProfileById>;

export const useCampaignLeadProfileById = () => {
  const [fetchCampaignLeadProfileById, { data, loading, error }] =
    useLazyQuery<GetCampaignLeadProfileByIdQuery>(getCampaignLeadProfileById);

  const campaignLeadProfile =
    data && data.campaign_lead_profile_by_pk ? data.campaign_lead_profile_by_pk : null;

  const saleActionStatuses = campaignLeadProfile?.campaign_lead_profile_sale_action_statuses ?? [];

  const lastStatus =
    saleActionStatuses.length > 0 ? saleActionStatuses[saleActionStatuses.length - 1] : null;

  return {
    campaignLeadProfile:
      data && data.campaign_lead_profile_by_pk ? { ...campaignLeadProfile, lastStatus } : null,
    campaignLeadProfileError: error,
    campaignLeadProfileLoading: loading,
    fetchCampaignLeadProfileById,
  };
};
