import { gql } from "@apollo/client";

export const getSaleActionStatuses = gql`
  query getSaleActionStatuses {
    sale_action_status {
      id
      name
    }
  }
`;
