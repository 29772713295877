import { ColumnSort } from "@tanstack/react-table";
import { Badge, BadgeProps } from "../../../../shared/components/badge/Badge";
import { SALE_ACTION_STATUS } from "../../../../shared/constants/models";
import { ConversationMessage } from "../types";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { Message, MessageSeparator } from "@chatscope/chat-ui-kit-react";
import React from "react";
import relativeTime from "dayjs/plugin/relativeTime";

dayjs.extend(utc);
dayjs.extend(relativeTime);

export const translateSortingQuery = ({ sorting }: { sorting: ColumnSort[] }) => {
  return sorting.map(sort => {
    const fields = sort.id.split(".");
    const sortOrder = sort.desc ? "desc_nulls_last" : "asc_nulls_first";

    if (sort.id === "conversation_thread_last_inbound_message_external_created_at") {
      return {
        conversation_thread: {
          conversation_messages_aggregate: {
            max: {
              external_created_at: sortOrder,
            },
          },
        },
      };
    }
    if (sort.id === "last_sale_action_status") {
      return {
        last_sale_action_status_id: sortOrder,
      };
    }

    if (fields.length > 1) {
      let result = {};
      let current: any = result;
      for (let i = 0; i < fields.length - 1; i++) {
        current[fields[i]] = {};
        current = current[fields[i]];
      }
      current[fields[fields.length - 1]] = sortOrder;
      return result;
    }

    return {
      [sort.id]: sortOrder,
    };
  });
};

export const getSaleStatus = (statusId: number | undefined, statusName: string | undefined) => {
  if (!statusId) {
    return <Badge variant={"neutral"}>No Sale Status</Badge>;
  }

  if ([SALE_ACTION_STATUS.DEAL_LOST].includes(statusId)) {
    return <Badge variant={"error"}>{statusName}</Badge>;
  }

  if ([SALE_ACTION_STATUS.QUALIFIED, SALE_ACTION_STATUS.INTERESTED].includes(statusId)) {
    return <Badge variant={"warning"}>{statusName}</Badge>;
  }

  if (
    [
      SALE_ACTION_STATUS.CALL_BOOKED,
      SALE_ACTION_STATUS.VALUATION_WON,
      SALE_ACTION_STATUS.INSTRUCTION_WON,
      SALE_ACTION_STATUS.SSTC,
      SALE_ACTION_STATUS.SOLD,
    ].includes(statusId)
  ) {
    return <Badge variant={"success"}>{statusName}</Badge>;
  }

  return <Badge variant={"neutral"}>{statusName}</Badge>;
};

export const getCorrectMatchStatus = (isIncorrectMatch: boolean | null | undefined) => {
  if (isIncorrectMatch === null || isIncorrectMatch === undefined) {
    return <Badge variant={"neutral"}>Unknown Match</Badge>;
  }

  if (isIncorrectMatch === false) {
    return <Badge variant={"success"}>Correct Match</Badge>;
  }

  if (isIncorrectMatch === true) {
    return <Badge variant={"error"}>Incorrect Match</Badge>;
  }
};

export const getPersonalConnectionStatus = (isPersonalConnection: boolean | null | undefined) => {
  if (isPersonalConnection === true) {
    return <Badge variant={"success"}>Personal Connection</Badge>;
  }

  return <Badge variant={"neutral"}>No Personal Connection</Badge>;
};

export const getDoNotMessageStatus = (isDoNotMessage: boolean | null | undefined) => {
  if (isDoNotMessage === true) {
    return <Badge variant={"error"}>Do Not Message</Badge>;
  } else {
    return <Badge variant={"success"}>Can Message</Badge>;
  }
};

export const generateFormattedMessages = (conversationMessages: ConversationMessage[]) => {
  let lastDate: string | null = null;
  let firstUnseenMessage = true;

  return (
    conversationMessages.map((message, index) => {
      const messageDate = dayjs.utc(message.external_created_at).format("YYYY-MM-DD");
      let separator = null;

      if (messageDate !== lastDate) {
        separator = (
          <MessageSeparator className="my-2" as={"h2"}>
            {dayjs.utc(message.external_created_at).format("dddd, D MMMM YYYY")}
          </MessageSeparator>
        );
        lastDate = messageDate;
      }

      let unseenSeparator = null;
      if (!message.user_seen_at && firstUnseenMessage) {
        unseenSeparator = <MessageSeparator as={"h2"}>New</MessageSeparator>;
        firstUnseenMessage = false;
      }

      return (
        <React.Fragment key={index}>
          {separator}
          {/* {unseenSeparator} */}
          <Message
            model={{
              message: message.content,
              sentTime: dayjs.utc(message.external_created_at).fromNow(),
              sender: message.is_outbound ? "Agent" : "Lead",
              direction: message.is_outbound ? "outgoing" : "incoming",
              position: "single",
            }}
          >
            <Message.Header>{message.conversation_message_status}</Message.Header>

            <Message.Footer sentTime={dayjs.utc(message.external_created_at).format("HH:mm")} />
          </Message>
        </React.Fragment>
      );
    }) ?? []
  );
};
