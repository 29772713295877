import { ApolloClient, InMemoryCache, ApolloProvider, createHttpLink } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import config from "../../../../config";

// LATEST TODO: SECURITY, GIVE USERS NON ADMIN PERMISSION
const ApolloWrapper = ({ children }: { children: React.ReactNode }) => {
  const httpLink = createHttpLink({
    uri: `${config.hasuraEndpoint}/v1/graphql`,
  });

  const authLink = setContext(async (_, { headers }) => {
    return {
      headers: {
        ...headers,
        "x-hasura-admin-secret": `${config.hasuraAdminSecret}`,
      },
    };
  });

  const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: "cache-and-network",
      },
    },
  });

  if (!client) {
    return null;
  }

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default ApolloWrapper;
