import { useEffect, useState } from "react";
import { useRepliedCampaignLeadProfiles } from "../../../../shared/domains/campaigns/graphql/hooks/useRepliedCampaignLeadProfiles";
import { getColumns } from "../components/Columns";
import { DataTable } from "../components/DataTable";
import { PAGE_SIZE } from "../data";
import { ColumnSort } from "@tanstack/react-table";
import { translateSortingQuery } from "../utils/index";
import { useCampaignLeadProfileById } from "../../../../shared/domains/campaigns/graphql/hooks/useCampaignLeadProfileById";
import { DataTableDrawer } from "../components/DataTableDrawer";
import { useTagCampaignLeadProfileAPI } from "../../../../shared/domains/campaigns/rest/tagCampaignLeadProfileAPI";
import { useSendMessageAPI } from "../../../../shared/domains/conversations/rest/sendMessageAPI";
import {
  useToggleReadStatusOnConversationThread,
  ToggleReadActionType,
  ToggleReadType,
} from "../../../../shared/domains/conversations/rest/toggleReadStatusAPI";
import toast from "react-hot-toast";
import { useSearchParams } from "react-router-dom";
import { useSaleActionStatuses } from "../../../../shared/domains/static/graphql/hooks/useSaleActionStatuses";
import { usePagination } from "../../../../shared/components/table/usePagination";
import { useDebounce } from "../../../../shared/hooks/UseDebounce";
import dayjs from "dayjs";
import {
  conversationValueMap,
  ConversationValueOption,
  INFINITY_IN_DAYS_FOR_MEETMORE,
  INIFITY_FOR_MAX_CONVERSATION_VALUE,
} from "../data/data";
import { useCurrentUserContext } from "../../../../shared/domains/users/graphql/hooks/useCurrentUserContext";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../../../shared/infrastructure/firebase";

export const QUERY_PARAM_LEAD_PROFILE_ID = "lead-profile-id";

export default function Conversations() {
  const [sorting, setSorting] = useState<ColumnSort[]>([]);
  const [triggerFilter, setTriggerFilter] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [lastMessageFilterDays, setLastMessageFilterDays] = useState<number>(0);
  const [conversationValueOption, setConversationValueOption] = useState<number>(
    ConversationValueOption.DEFAULT
  );
  const [saleActionStatusIds, setSaleActionStatusIds] = useState<number[]>([]);
  const [searchParams, setSearchParams] = useSearchParams();

  const [user] = useAuthState(auth);
  const { currentWorkspaceId } = useCurrentUserContext({
    externalId: user?.uid || "",
  });

  const { saleActionStatuses } = useSaleActionStatuses();

  useEffect(() => {
    if (saleActionStatuses.length > 0) {
      setSaleActionStatusIds(saleActionStatuses.map(status => status.id));
    }
  }, [saleActionStatuses]);

  const handleSearchNameChange = (needle: string) => {
    setSearchTerm(needle);
  };
  const debouncedSearchTerm = useDebounce(searchTerm, 300);

  const onSaleActionStatusChange = (selectedIds: number[]) => {
    if (selectedIds.length === 0) {
      setSaleActionStatusIds(saleActionStatuses.map(status => status.id));
      setOffset(0);
      setSearchTerm("");
    } else {
      setSaleActionStatusIds(selectedIds);
      setOffset(0);
      setSearchTerm("");
    }
    setTriggerFilter(false);
  };

  const handleLastReplyFilterChange = (value: number) => {
    setLastMessageFilterDays(value);
    setOffset(0);
  };

  const handleConversationValueFilterChange = (value: number) => {
    setConversationValueOption(value);
    setOffset(0);
    setTriggerFilter(false);
  };

  const lastReplyDaysAgoValue =
    lastMessageFilterDays === 0 ? INFINITY_IN_DAYS_FOR_MEETMORE : lastMessageFilterDays;
  const lastReplyDaysAgo = dayjs().subtract(lastReplyDaysAgoValue, "day").format("YYYY-MM-DD");

  const columns = getColumns({
    onEditClick: async row => {
      searchParams.set(QUERY_PARAM_LEAD_PROFILE_ID, String(row.original.id));
      setSearchParams(searchParams);
    },
  });

  const currentCampaignLeadProfileId = searchParams.get(QUERY_PARAM_LEAD_PROFILE_ID)
    ? Number(searchParams.get(QUERY_PARAM_LEAD_PROFILE_ID))
    : null;

  useEffect(() => {
    const load = async () => {
      await fetchCampaignLeadProfileById({ variables: { id: currentCampaignLeadProfileId } });
    };

    if (currentCampaignLeadProfileId) {
      load();
    }
  }, [searchParams]);

  useEffect(() => {
    const load = async () => {
      await fetchCampaignLeadProfileById({ variables: { id: currentCampaignLeadProfileId } });
    };

    if (currentCampaignLeadProfileId) {
      load();
    }
  }, [searchParams]);

  const {
    fetchRepliedCampaignLeadProfiles,
    repliedCampaignLeadProfiles,
    repliedCampaignLeadProfilesCount,
  } = useRepliedCampaignLeadProfiles();

  const { offset, setOffset } = usePagination({
    pageSize: PAGE_SIZE,
    totalRecords: repliedCampaignLeadProfilesCount,
  });

  const { campaignLeadProfile, fetchCampaignLeadProfileById } = useCampaignLeadProfileById();

  const {
    mutateAsync: tagCampaignLeadProfileMutation,
    isSuccess: tagCampaignLeadProfileMutationSuccess,
    isLoading: tagCampaignLeadProfileMutationLoading,
  } = useTagCampaignLeadProfileAPI();

  const { mutateAsync: markAsReadMutation, isSuccess: marAsReadSuccess } =
    useToggleReadStatusOnConversationThread({
      showToasts: false,
    });

  const { mutateAsync: markAsUnreadMutation, isSuccess: marAsUnReadSuccess } =
    useToggleReadStatusOnConversationThread({
      showToasts: true,
    });

  const conversationValueMin = conversationValueMap[conversationValueOption]?.min ?? 0;
  const conversationValueMax =
    conversationValueMap[conversationValueOption]?.max ?? INIFITY_FOR_MAX_CONVERSATION_VALUE;
  useEffect(() => {
    fetchRepliedCampaignLeadProfiles({
      variables: {
        limit: PAGE_SIZE,
        offset: offset,
        orderBy: translateSortingQuery({ sorting }),
        saleActionStatusIds: saleActionStatusIds,
        nameFilter: debouncedSearchTerm ? `%${debouncedSearchTerm}%` : "%%",
        workspacesIds: currentWorkspaceId ? [currentWorkspaceId] : [],
        lastReplyDaysAgo,
        conversationValueMin,
        conversationValueMax,
      },
    });
    if (campaignLeadProfile?.id) {
      fetchCampaignLeadProfileById({ variables: { id: campaignLeadProfile?.id } });
    }
  }, [
    lastMessageFilterDays,
    saleActionStatusIds,
    currentWorkspaceId,
    tagCampaignLeadProfileMutationSuccess,
    marAsReadSuccess,
    marAsUnReadSuccess,
    conversationValueMax,
    conversationValueMin,
    offset,
    sorting,
    debouncedSearchTerm,
  ]);

  const handleOnPaginationChange = ({ offset }: { offset: number; limit: number }) => {
    setOffset(offset);
  };

  const resetFilters = () => {
    setSaleActionStatusIds(saleActionStatuses.map(status => status.id));
    setLastMessageFilterDays(0);
    setConversationValueOption(0);
    setSearchTerm("");
    setOffset(0);
    setTriggerFilter(true);
  };

  const isFiltered =
    saleActionStatusIds.length !== saleActionStatuses.length ||
    lastMessageFilterDays !== 0 ||
    conversationValueOption !== 0 ||
    debouncedSearchTerm !== "";

  const markAsRead = (threadId: number) => {
    if (!threadId) {
      toast.error("Error: Cannot mark conversation thread as read");
      return;
    }
    markAsReadMutation({
      conversationThreadId: threadId,
      action: ToggleReadActionType.READ,
      type: ToggleReadType.WORKSPACE,
    });
  };

  const markAsUnread = (threadId: number) => {
    if (!threadId) {
      toast.error("Error: Cannot mark conversation thread as unread");
      return;
    }
    markAsUnreadMutation({
      conversationThreadId: threadId,
      action: ToggleReadActionType.UNREAD,
      type: ToggleReadType.WORKSPACE,
    });
  };

  const saleActionStatusesOptions = saleActionStatuses.map(status => ({
    id: status.id,
    name: status.name,
  }));

  const closeDrawer = () => {
    searchParams.delete(QUERY_PARAM_LEAD_PROFILE_ID);
    setSearchParams(searchParams);
  };

  return (
    <>
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-lg font-semibold text-gray-900 sm:text-xl dark:text-gray-50">
            Conversations
          </h1>{" "}
          <p className="mt-2 text-sm text-gray-700">
            View and manage all your conversations in one place.
          </p>
        </div>
      </div>
      <div className="mt-4 sm:mt-6 lg:mt-10">
        <DataTable
          searchTerm={searchTerm}
          handleSearchChange={handleSearchNameChange}
          isFiltered={isFiltered}
          onSaleActionStatusChange={onSaleActionStatusChange}
          saleActionStatusesOptions={saleActionStatusesOptions}
          data={repliedCampaignLeadProfiles}
          columns={columns}
          totalRecords={repliedCampaignLeadProfilesCount}
          offset={offset}
          resetFilters={resetFilters}
          setSorting={setSorting}
          triggerFilter={triggerFilter}
          onLastReplyFilterChange={handleLastReplyFilterChange}
          onConversationValueFilterChange={handleConversationValueFilterChange}
          sorting={sorting}
          onPaginationChange={handleOnPaginationChange}
        />
        {campaignLeadProfile && (
          <DataTableDrawer
            tagCampaignLeadProfileMutation={tagCampaignLeadProfileMutation}
            markAsRead={markAsRead}
            markAsUnread={markAsUnread}
            tagCampaignLeadProfileMutationLoading={tagCampaignLeadProfileMutationLoading}
            // outreachAgentTypes={outreachAgentTypes}
            // outreachPlatforms={outreachPlatforms}
            // campaignExternalConnectionProviders={campaignExternalConnectionProviders}
            // updateOutreachAgentMutationLoading={updateOutreachAgentMutationLoading}
            // updateOutreachAgentOnSubmit={updateOutreachAgentOnSubmit}
            // updateOutreachAgentErrors={updateOutreachAgentErrors}
            // updateOutreachAgentControl={updateOutreachAgentControl}
            // updateOutreachAgentRegister={updateOutreachAgentRegister}
            // updateOutreachAgentHandleSubmit={updateOutreachAgentHandleSubmit}
            // updateOutreachAgentSetValue={updateOutreachAgentSetValue}
            open={!!currentCampaignLeadProfileId}
            onOpenChange={closeDrawer}
            data={campaignLeadProfile}
          />
        )}
      </div>
    </>
  );
}
