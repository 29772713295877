const AccessDenied = () => {
  return (
    <main className="grid min-h-screen  flex flex-col items-center justify-center place-items-center bg-white ">
      <div className="text-center">
        <h1 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">
          Access Denied
        </h1>
        <p className="mt-6 text-base leading-7 text-gray-600">
          You have no permission to visit this page
        </p>
        <div className="mt-4 flex items-center justify-center gap-x-6">
          <a href="mailto:abdi@meetmore.ai" className="text-sm font-semibold text-gray-900">
            Did we get it wrong? Contact support <span aria-hidden="true">&rarr;</span>
          </a>
        </div>
      </div>
    </main>
  );
};

export default AccessDenied;
