import { getSaleActionStatuses } from "../queries/getSaleActionStatuses";
import { GetSaleActionStatusesQuery } from "../../../../infrastructure/graphql/core/generatedTypes";
import { useQuery } from "@apollo/client";

export type UseSaleActionStatuses = ReturnType<typeof useSaleActionStatuses>;

export const useSaleActionStatuses = () => {
  const { data, loading, error } = useQuery<GetSaleActionStatusesQuery>(getSaleActionStatuses);
  return {
    saleActionStatuses:
      data && Array.isArray(data.sale_action_status) ? data.sale_action_status : [],
    saleActionStatusesError: error,
    saleActionStatusesLoading: loading,
  };
};
