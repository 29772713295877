import { gql } from "@apollo/client";

const commonFilter = `
  campaign_lead: {
    campaign: {
      workspace_id: { _in: $workspaceIds }
    }
  }
  conversation_value: { _gt: 0 }
  conversation_thread_id: { _is_null: false }
  conversation_thread: { created_at: { _gte: $afterDate } }
`;

export const getDashboardAnalyticsData = gql`
  query getDashboardAnalyticsData($workspaceIds: [Int!], $afterDate: timestamptz!) {
    campaign_lead_profile_aggregate(
      where: {
        ${commonFilter}
      }
    ) {
      aggregate {
        sum {
          conversation_value
        }
        count
      }
    }
    lost_conversations: campaign_lead_profile_sale_action_status_aggregate(
      where: {
        sale_action_status_id: { _in: [1] }
        campaign_lead_profile: {
          ${commonFilter}
        }
      }
    ) {
      aggregate {
        count
      }
    }
    progressed_conversations: campaign_lead_profile_sale_action_status_aggregate(
      where: {
        sale_action_status_id: { _in: [5, 6, 7, 8] }
        campaign_lead_profile: {
          ${commonFilter}
        }
      }
    ) {
      aggregate {
        count
      }
    }
    active_conversations: campaign_lead_profile_sale_action_status_aggregate(
      where: {
        sale_action_status_id: { _nin: [1, 5, 6, 7, 8] }
        campaign_lead_profile: {
          ${commonFilter}
        }
      }
    ) {
      aggregate {
        count
      }
    }
    instruction_won_conversations: campaign_lead_profile_sale_action_status_aggregate(
      where: {
        sale_action_status_id: { _in: [6] }
        campaign_lead_profile: {
          ${commonFilter}
        }
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;
