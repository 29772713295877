import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import { auth } from "../../firebase";

export class HttpClient {
  instance: AxiosInstance;
  token: string;

  constructor(baseUrl: string, overrides?: Partial<AxiosRequestConfig>) {
    this.instance = axios.create({
      baseURL: baseUrl,
      ...overrides,
    });

    this.instance.interceptors.request.use(
      async config => {
        const user = auth.currentUser;

        if (user) {
          const token = (await user.getIdTokenResult()).token;
          config.headers = { ...config.headers, Authorization: `Bearer ${token}` };
        }

        // Always return the config object.
        return config;
      },
      error => {
        // Always return a Promise that rejects with the error to keep the chain going.
        return Promise.reject(error);
      }
    );

    this.token = "";

    return this;
  }
}
