import * as yup from "yup";

// TODO: inferType is not working
export type UpdateSaleActionStatusValues = {
  saleStatusId: string;
};

export const updateSaleActionStatusSchema = yup.object().shape({
  saleStatusId: yup.string().required("Status is required"),
});

export type UpdateBooleanFieldValues = {
  field: string;
};

export const updateBooleanFieldSchema = yup.object().shape({
  field: yup.string().required("Field is required"),
});
