import { initializeApp } from "firebase/app";
import {
  getAuth,
  connectAuthEmulator,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  GoogleAuthProvider,
  GithubAuthProvider,
  OAuthProvider,
  signInAnonymously,
  signOut,
  sendEmailVerification,
  updateProfile,
  reauthenticateWithCredential,
  updatePassword,
  User,
  EmailAuthProvider,
  signInWithPopup,
  sendSignInLinkToEmail,
  isSignInWithEmailLink,
  signInWithEmailLink,
  setPersistence,
  browserLocalPersistence,
} from "firebase/auth";
import toast from "react-hot-toast";
import config from "../../../config";

const app = initializeApp({ apiKey: config.firebaseApiKey, authDomain: config.firebaseAuthDomain });
const auth = getAuth(app);
setPersistence(auth, browserLocalPersistence);
const googleProvider = new GoogleAuthProvider();
const githubProvider = new GithubAuthProvider();
const microsoftProvider = new OAuthProvider("microsoft.com");

const sendSignInLink = async ({ email, hostname }: { email: string; hostname: string }) => {
  try {
    const actionCodeSettings = {
      url: `https://${hostname}/handle-sign-up`, // Here you would put the url of your application's sign-in completion page
      handleCodeInApp: true,
    };
    await sendSignInLinkToEmail(auth, email, actionCodeSettings);
    // Save the email locally so you can complete the sign in later
    window.localStorage.setItem("emailForSignIn", email);
    toast.success("Sign in link sent to your email. Be sure to check Spam.");
  } catch (err) {
    toast.error(err.code);
  }
};

const signInWithLink = async ({ email, link }: { email: string; link: string }) => {
  try {
    if (isSignInWithEmailLink(auth, link)) {
      await signInWithEmailLink(auth, email, link);
      window.localStorage.removeItem("emailForSignIn");
    }
  } catch (err) {
    toast.error(err.code);
  }
};

const loginAnonymously = async () => {
  try {
    await signInAnonymously(auth);
  } catch (err) {
    toast.error(err.code);
  }
};

const logInWithEmailAndPassword = async ({
  email,
  password,
}: {
  email: string;
  password: string;
}) => {
  try {
    await signInWithEmailAndPassword(auth, email, password);
  } catch (err) {
    toast.error(err.code);
  }
};

const logInWithGoogle = async () => {
  try {
    await signInWithPopup(auth, googleProvider);
  } catch (err) {
    toast.error(err.code);
  }
};

const logInWithGithub = async () => {
  try {
    await signInWithPopup(auth, githubProvider);
  } catch (err) {
    toast.error(err.code);
  }
};

const logInWithMicrosoft = async () => {
  try {
    await signInWithPopup(auth, microsoftProvider);
  } catch (err) {
    toast.error(err.code);
  }
};

const changePassword = async ({
  user,
  currentPassword,
  newPassword,
}: {
  user: User;
  currentPassword: string;
  newPassword: string;
}) => {
  toast.loading("Changing Password....");
  try {
    if (user && user.email) {
      const credential = EmailAuthProvider.credential(user.email, currentPassword);
      await reauthenticateWithCredential(user, credential);
      await updatePassword(user, newPassword);
      toast.success("Password Changed");
    }
  } catch (err) {
    toast.error(err.code);
  }
};

const sendVerificationEmail = async ({ user }: { user: User }) => {
  toast.loading("Sending Email....");
  try {
    if (user && user.emailVerified === false) {
      await sendEmailVerification(user);
      toast.success("Verification Email Sent");
    }
  } catch (err) {
    toast.error(err.code);
  }
};

const registerWithEmailAndPassword = async ({
  email,
  password,
  name,
}: {
  email: string;
  password: string;
  name: string;
}) => {
  try {
    const { user } = await createUserWithEmailAndPassword(auth, email, password);
    await updateProfile(user, { displayName: name });
    if (user && user.emailVerified === false) {
      await sendEmailVerification(user);
      toast.success("Account Created: Please verify email");
    }
  } catch (err) {
    toast.error(err.code);
  }
};
const sendPasswordReset = async ({ email }: { email: string }) => {
  try {
    await sendPasswordResetEmail(auth, email);
    toast.success("Password Reset Email Sent");
  } catch (err) {
    toast.error(err.code);
  }
};

const logout = async () => {
  try {
    await signOut(auth);
    toast.success("Logged Out Successfully");

    window.location.href = "/";
  } catch (err) {
    toast.error(err.code);
  }
};

const refreshToken = async ({ user }: { user: User }): Promise<void> => {
  await user.getIdToken(true);
};

if (config.environment === "local") {
  connectAuthEmulator(auth, "http://127.0.0.1:9099");
}

export {
  auth,
  logInWithEmailAndPassword,
  sendVerificationEmail,
  registerWithEmailAndPassword,
  sendPasswordReset,
  logout,
  changePassword,
  refreshToken,
  logInWithGoogle,
  logInWithGithub,
  sendSignInLink,
  signInWithLink,
  logInWithMicrosoft,
  loginAnonymously,
};
