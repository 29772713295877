import clsx, { type ClassValue } from "clsx";
import { twMerge } from "tailwind-merge";

export function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

export type ArrayElement<ArrayType extends readonly unknown[]> =
  ArrayType extends readonly (infer ElementType)[] ? ElementType : never;

export function cx(...args: ClassValue[]) {
  return twMerge(clsx(...args));
}

// Tremor Raw focusInput [v0.0.1]

export const focusInput = [
  // base
  "focus:ring-2",
  // ring color
  "focus:ring-orange-200 focus:dark:ring-orange-700/30",
  // border color
  "focus:border-orange-500 focus:dark:border-orange-700",
];

// Tremor Raw focusRing [v0.0.1]

export const focusRing = [
  // base
  "outline outline-offset-2 outline-0 focus-visible:outline-2",
  // outline color
  "outline-orange-500 dark:outline-orange-500",
];

// Tremor Raw hasErrorInput [v0.0.1]

export const hasErrorInput = [
  // base
  "ring-2",
  // border color
  "border-red-500 dark:border-red-700",
  // ring color
  "ring-red-200 dark:ring-red-700/30",
];

export const formatters: { [key: string]: any } = {
  currency: ({
    number,
    maxFractionDigits = 0,
    currency = "GBP",
  }: {
    number: number;
    maxFractionDigits?: number;
    currency?: string;
  }) => {
    const formattedNumber = new Intl.NumberFormat("en-GB", {
      style: "currency",
      currency: currency,
      maximumFractionDigits: maxFractionDigits,
    }).format(number);

    return `${formattedNumber}`;
  },

  unit: (number: number) => {
    const formattedNumber = new Intl.NumberFormat("en-GB", {
      style: "decimal",
    }).format(number);
    return `${formattedNumber}`;
  },

  percentage: ({ number, decimals = 1 }: { number: number; decimals?: number }) => {
    const formattedNumber = new Intl.NumberFormat("en-GB", {
      style: "percent",
      minimumFractionDigits: decimals,
      maximumFractionDigits: decimals,
    }).format(number);
    const symbol = number > 0 && number !== Infinity ? "+" : "";

    return `${symbol}${formattedNumber}`;
  },

  million: ({ number, decimals = 1 }: { number: number; decimals?: number }) => {
    const formattedNumber = new Intl.NumberFormat("en-GB", {
      style: "decimal",
      minimumFractionDigits: decimals,
      maximumFractionDigits: decimals,
    }).format(number);
    return `${formattedNumber}M`;
  },
};
