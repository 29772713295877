import { ChevronLeft, ChevronRight, ChevronsLeft, ChevronsRight } from "lucide-react";

import { Table } from "@tanstack/react-table";
import { cx, formatters } from "../../utils";
import { Button } from "../button/Button";

interface TablePaginationServerSideProps<TData> {
  table: Table<TData>;
  pageSize: number;
  offset: number;
  totalRecords: number;
  onChangeHandler: ({ offset, limit }: { offset: number; limit: number }) => void;
}

export function TablePaginationServerSide<TData>({
  table,
  pageSize,
  offset,
  onChangeHandler,
  totalRecords,
}: TablePaginationServerSideProps<TData>) {
  const currentPage = Math.floor(offset / pageSize);
  const totalPages = Math.ceil(totalRecords / pageSize);

  const paginationButtons = [
    {
      icon: ChevronsLeft,
      onClick: () => onChangeHandler({ offset: 0, limit: pageSize }),
      disabled: currentPage === 0,
      srText: "First page",
      mobileView: "hidden sm:block",
    },
    {
      icon: ChevronLeft,
      onClick: () => onChangeHandler({ offset: Math.max(0, offset - pageSize), limit: pageSize }),
      disabled: currentPage === 0,
      srText: "Previous page",
      mobileView: "",
    },
    {
      icon: ChevronRight,
      onClick: () =>
        onChangeHandler({
          offset: Math.min(totalRecords - (totalRecords % pageSize || pageSize), offset + pageSize),
          limit: pageSize,
        }),
      disabled: offset + pageSize >= totalRecords,
      srText: "Next page",
      mobileView: "",
    },
    {
      icon: ChevronsRight,
      onClick: () => onChangeHandler({ offset: (totalPages - 1) * pageSize, limit: pageSize }),
      disabled: currentPage >= totalPages - 1,
      srText: "Last page",
      mobileView: "hidden sm:block",
    },
  ];

  const firstRowIndex = offset + 1;
  const lastRowIndex = Math.min(totalRecords, offset + pageSize);

  return (
    <div className="flex items-center justify-between">
      <div className="text-sm tabular-nums text-gray-500"></div>
      <div className="flex items-center gap-x-6 lg:gap-x-8">
        <p className="hidden text-sm tabular-nums text-gray-500 sm:block">
          Showing{" "}
          <span className="font-medium text-gray-900 dark:text-gray-50">
            {firstRowIndex}-{lastRowIndex}
          </span>{" "}
          of{" "}
          <span className="font-medium text-gray-900 dark:text-gray-50">
            {formatters.unit(totalRecords)}
          </span>
        </p>
        <div className="flex items-center gap-x-1.5">
          {paginationButtons.map((button, index) => (
            <Button
              key={index}
              variant="secondary"
              className={cx(button.mobileView, "p-1.5")}
              onClick={() => {
                button.onClick();
                table.resetRowSelection();
              }}
              disabled={button.disabled}
            >
              <span className="sr-only">{button.srText}</span>
              <button.icon className="size-4 shrink-0" aria-hidden="true" />
            </Button>
          ))}
        </div>
      </div>
    </div>
  );
}
