export const INFINITY_IN_DAYS_FOR_MEETMORE = 365 * 2;
export const INIFITY_FOR_MAX_CONVERSATION_VALUE = 999999999;

const ALL_CONVERSATIONS_VALUE_OPTION = {
  min: 0,
  max: INIFITY_FOR_MAX_CONVERSATION_VALUE,
};

export enum ConversationValueOption {
  DEFAULT = 0,
  ZERO_TO_HUNDRED = 1,
  HUNDRED_TO_TWO_HUNDRED = 2,
  TWO_HUNDRED_TO_FIVE_HUNDRED = 3,
  FIVE_HUNDRED_TO_SEVEN_HUNDRED_FIFTY = 4,
  SEVEN_HUNDRED_FIFTY_TO_THOUSAND = 5,
  THOUSAND_TO_FIFTEEN_HUNDRED = 6,
  FIFTEEN_HUNDRED_TO_TWO_THOUSAND = 7,
  TWO_THOUSAND_TO_FIVE_THOUSAND = 8,
  FIVE_THOUSAND_PLUS = 9,
}

export const conversationValueMap: Record<number, { min: number; max: number }> = {
  [ConversationValueOption.DEFAULT]: ALL_CONVERSATIONS_VALUE_OPTION,
  [ConversationValueOption.ZERO_TO_HUNDRED]: { min: 0, max: 100 },
  [ConversationValueOption.HUNDRED_TO_TWO_HUNDRED]: { min: 101, max: 200 },
  [ConversationValueOption.TWO_HUNDRED_TO_FIVE_HUNDRED]: { min: 201, max: 500 },
  [ConversationValueOption.FIVE_HUNDRED_TO_SEVEN_HUNDRED_FIFTY]: { min: 501, max: 750 },
  [ConversationValueOption.SEVEN_HUNDRED_FIFTY_TO_THOUSAND]: { min: 751, max: 1000 },
  [ConversationValueOption.THOUSAND_TO_FIFTEEN_HUNDRED]: { min: 1001, max: 1500 },
  [ConversationValueOption.FIFTEEN_HUNDRED_TO_TWO_THOUSAND]: { min: 1501, max: 2000 },
  [ConversationValueOption.TWO_THOUSAND_TO_FIVE_THOUSAND]: { min: 2000, max: 5000 },
  [ConversationValueOption.FIVE_THOUSAND_PLUS]: {
    min: 5001,
    max: INIFITY_FOR_MAX_CONVERSATION_VALUE,
  },
};

export const lastReplyExternalCreatedAtDaysOptions = [
  { value: 1, label: "last 24 hrs" },
  { value: 3, label: "last 3 days" },
  { value: 7, label: "last week" },
  { value: 14, label: "last 2 weeks" },
  { value: 30, label: "last month" },
  { value: 90, label: "last 3 months" },
  { value: 180, label: "last 6 months" },
  { value: 365, label: "last 1 year" },
];

export const conversationValueOptions = [
  { value: ConversationValueOption.ZERO_TO_HUNDRED, label: "0-100" },
  { value: ConversationValueOption.HUNDRED_TO_TWO_HUNDRED, label: "101-200" },
  { value: ConversationValueOption.TWO_HUNDRED_TO_FIVE_HUNDRED, label: "201-500" },
  { value: ConversationValueOption.FIVE_HUNDRED_TO_SEVEN_HUNDRED_FIFTY, label: "501-750" },
  { value: ConversationValueOption.SEVEN_HUNDRED_FIFTY_TO_THOUSAND, label: "751-1000" },
  { value: ConversationValueOption.THOUSAND_TO_FIFTEEN_HUNDRED, label: "1001-1500" },
  { value: ConversationValueOption.FIFTEEN_HUNDRED_TO_TWO_THOUSAND, label: "1501-2000" },
  { value: ConversationValueOption.TWO_THOUSAND_TO_FIVE_THOUSAND, label: "2000-5000" },
  { value: ConversationValueOption.FIVE_THOUSAND_PLUS, label: "5001+" },
];
