import toast from "react-hot-toast";
import { useMutation } from "react-query";
import { meetmoreAPI } from "../../../../infrastructure/APIs/meetmoreAPI";

export enum ToggleReadActionType {
  READ = "read",
  UNREAD = "unread",
}
export enum ToggleReadType {
  ADMIN = "admin",
  WORKSPACE = "workspace",
}

type Payload = {
  conversationThreadId: number;
  action: ToggleReadActionType;
  type: ToggleReadType;
};

export const toggleReadStatusAPI = async ({ conversationThreadId, action, type }: Payload) => {
  const payload = {
    action,
    type,
  };

  const response = await meetmoreAPI.post(
    `/conversation-threads/${conversationThreadId}/toggle-read-status`,
    payload,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  return response.data;
};

export const useToggleReadStatusOnConversationThread = ({
  showToasts = false,
}: {
  showToasts?: boolean;
}) => {
  let loadingToastId: string;

  return useMutation(toggleReadStatusAPI, {
    onMutate: () => {
      // Popup a toast here to inform user that the mutation is in progress
      if (showToasts) {
        loadingToastId = toast.loading("Updating thread read status...");
      }
      // Return loadingToastId so it can be used in onSettled
    },
    onSuccess: async (data, variables, context) => {
      // Notify the user of the successful mutation
      if (showToasts) {
        toast.success("Read status updated!");
      }
    },
    onError: (error: any, variables, context) => {
      // Notify the user if the mutation fails
      toast.error(`Error: ${error?.response?.data?.errors[0]?.message ?? error?.message}`);
    },
    onSettled: (data, error, variables, context) => {
      // Dismiss the loading toast regardless of success or failure
      if (loadingToastId) {
        toast.dismiss(loadingToastId);
      }
    },
  });
};
