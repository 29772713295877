import { useQuery } from "@apollo/client";
import { getUserWorkspacesByExternalId } from "../queries/getUserWorkspacesByExternalId";
import { GetUserWorkspacesByExternalIdQuery } from "../../../../infrastructure/graphql/core/generatedTypes";

export const useCurrentUserContext = ({ externalId }: { externalId: string }) => {
  const { data, loading, error } = useQuery<GetUserWorkspacesByExternalIdQuery>(
    getUserWorkspacesByExternalId,
    {
      variables: { externalId },
    }
  );

  return {
    currentWorkspaceId:
      data &&
      data.workspace_user &&
      Array.isArray(data.workspace_user) &&
      data.workspace_user.length > 0
        ? data.workspace_user[0].workspace_id
        : null,
    canAccessTheApp: data && data.workspace_user ? data.workspace_user.length === 1 : false,
    loading,
    error,
  };
};
