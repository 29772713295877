import { useQuery } from "@apollo/client";
import { GetDashboardAnalyticsDataQuery } from "../../../../infrastructure/graphql/core/generatedTypes";
import { getDashboardAnalyticsData } from "../queries/getDashboardAnalyticsData";

export type UseDashboardAnalyticsData = ReturnType<typeof useDashboardAnalyticsData>;

export const useDashboardAnalyticsData = ({
  workspaceIds,
  afterDate,
}: {
  workspaceIds: number[];
  afterDate: string;
}) => {
  const { data, loading, error, refetch } = useQuery<GetDashboardAnalyticsDataQuery>(
    getDashboardAnalyticsData,
    {
      variables: {
        workspaceIds,
        afterDate,
      },
    }
  );

  const pipelineGenerated =
    data?.campaign_lead_profile_aggregate?.aggregate?.sum?.conversation_value || 0;

  const numberOfConversions = data?.campaign_lead_profile_aggregate?.aggregate?.count || 0;

  const progressedConversations = data?.progressed_conversations?.aggregate?.count || 0;
  const lostConversations = data?.lost_conversations?.aggregate?.count || 0;
  const activeConversations = data?.active_conversations?.aggregate?.count || 0;
  const instructionWonConversations = data?.instruction_won_conversations?.aggregate?.count || 0;

  const totalConversations = progressedConversations + lostConversations;
  const opportunitiesProgressedPercentage =
    typeof totalConversations === "number" &&
    typeof progressedConversations === "number" &&
    totalConversations > 0
      ? (progressedConversations / totalConversations) * 100
      : 0;

  const totalInstructions = instructionWonConversations + lostConversations;
  const agentPerformancePercentage =
    typeof totalInstructions === "number" &&
    typeof instructionWonConversations === "number" &&
    typeof lostConversations === "number" &&
    totalInstructions > 0
      ? (instructionWonConversations / totalInstructions) * 100
      : 0;

  return {
    isLoading: loading,
    error: error,
    data: {
      pipelineGenerated,
      numberOfConversions,
      opportunitiesProgressedPercentage,
      numberOfProgressed: progressedConversations,
      numberOfLost: lostConversations,
      numberOfActive: activeConversations,
      agentPerformancePercentage,
      instructionWon: instructionWonConversations,
      instructionLost: lostConversations,
      activeOpportunities: activeConversations,
      totalConversations: numberOfConversions,
      conversationsWithValue: totalConversations,
    },
    refetchDashboardAnalyticsData: async ({
      workspaceIds,
      afterDate,
    }: {
      workspaceIds: number[];
      afterDate: string;
    }) => {
      await refetch({
        workspaceIds,
        afterDate,
      });
    },
  };
};
