import { UseMutateAsyncFunction } from "react-query";
import { TagCampaignLeadProfileBody } from "../../../../shared/domains/campaigns/rest/tagCampaignLeadProfileAPI";
import { useSaleActionStatuses } from "../../../../shared/domains/static/graphql/hooks/useSaleActionStatuses";
import { ConversationEditFields } from "../types";
import { Controller, useForm } from "react-hook-form";
import { updateSaleActionStatusSchema, UpdateSaleActionStatusValues } from "../validation";
import { yupResolver } from "@hookform/resolvers/yup";
import { UseCampaignLeadProfileById } from "../../../../shared/domains/campaigns/graphql/hooks/useCampaignLeadProfileById";
import { SALE_ACTION_STATUS_SOURCE } from "../../../../shared/constants/models";
import { Button } from "../../../../shared/components/button/Button";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../../shared/components/select/Select";
import ErrorMessage from "../../../../shared/components/errorMessage/ErrorMessage";

interface EditSaleStatusProps {
  setIsEditingField: React.Dispatch<React.SetStateAction<ConversationEditFields | undefined>>;
  tagCampaignLeadProfileMutation: UseMutateAsyncFunction<
    any,
    any,
    TagCampaignLeadProfileBody,
    void
  >;
  tagCampaignLeadProfileMutationLoading: boolean;
  campaignLeadProfileId: number;
}

const EditSaleStatus = ({
  tagCampaignLeadProfileMutation,
  tagCampaignLeadProfileMutationLoading,
  setIsEditingField,
  campaignLeadProfileId,
}: EditSaleStatusProps) => {
  const { saleActionStatuses } = useSaleActionStatuses();

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm<UpdateSaleActionStatusValues>({
    resolver: yupResolver(updateSaleActionStatusSchema),
  });

  const onSubmit = async (data: UpdateSaleActionStatusValues) => {
    await tagCampaignLeadProfileMutation({
      campaignLeadProfileId: campaignLeadProfileId,
      saleActionStatuses: [
        {
          saleActionStatusId: Number(data.saleStatusId),
          sourceId: SALE_ACTION_STATUS_SOURCE.AGENT,
        },
      ],
      propertyValues: [],
    });

    setIsEditingField(undefined);
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className="mt-4 w-full  space-y-4">
        <div>
          <Controller
            control={control}
            name="saleStatusId"
            render={({ field }) => {
              return (
                <Select onValueChange={field.onChange} {...field}>
                  <SelectTrigger id="platform" name="platform" className="mt-2">
                    <SelectValue />
                  </SelectTrigger>
                  <SelectContent align="end">
                    {saleActionStatuses.map(status => (
                      <SelectItem key={status.id} value={status.id?.toString()}>
                        {status.name}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              );
            }}
          ></Controller>
          {errors.saleStatusId && <ErrorMessage>{errors.saleStatusId.message}</ErrorMessage>}
        </div>

        <div className="mt-6 flex justify-between">
          <Button
            onClick={() => setIsEditingField(undefined)}
            type="button"
            className="mt-2 w-full sm:mt-0 sm:w-fit"
            variant="secondary"
          >
            Cancel
          </Button>
          <Button
            type="submit"
            disabled={tagCampaignLeadProfileMutationLoading}
            className="w-full sm:w-fit"
          >
            Confirm
          </Button>
        </div>
      </form>
    </>
  );
};

export default EditSaleStatus;
