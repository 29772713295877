import { ReactElement } from "react";
import SignIn from "../../../modules/authentication/signIn/page/SignIn";
import ForgotPassword from "../../../modules/authentication/forgotPassword/page/ForgotPass";
import AccessDenied from "../../../modules/authentication/accessDenied/page/AccessDenied";
import Overview from "../../../modules/analytics/overview/page/Overview";
import Conversations from "../../../modules/conversations/conversations/page/Conversations";

export interface Route {
  path: string;
  exact: boolean;
  main: (any: any) => ReactElement;
  redirectPath?: string;
}

// Routes that can be accessed anytime
export const publicRoutes: Route[] = [
  {
    path: "/access-denied",
    main: AccessDenied,
    exact: true,
  },
];

// routes that can be access when user is unauthenticated
export const unauthenticatedRoutes: Route[] = [
  {
    path: "/",
    main: SignIn,
    exact: true,
  },

  {
    path: "/forgot-password",
    main: ForgotPassword,
    exact: true,
  },
];

export const authenticatedLayoutRoutes: Route[] = [
  {
    path: "/analytics",
    main: Overview,
    exact: true,
  },
  {
    path: "/conversations",
    main: Conversations,
    exact: true,
  },
];

export const authenticatedNonLayoutRoutes: Route[] = [];
