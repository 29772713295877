import { useEffect } from "react";
import Header from "../components/Header";
import { useSearchParams } from "react-router-dom";
import { Card } from "../../../../shared/components/card/Card";
import { useDashboardAnalyticsData } from "../../../../shared/domains/outreachAgents/graphql/hooks/useDashboardAnalyticsData";
import dayjs from "dayjs";
import { QUERY_PARAM_DAYS_AGO } from "../components/FilterDate";
import { auth } from "../../../../shared/infrastructure/firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { useCurrentUserContext } from "../../../../shared/domains/users/graphql/hooks/useCurrentUserContext";

const Overview = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const afterDateInDays = searchParams.get(QUERY_PARAM_DAYS_AGO);

  const [user] = useAuthState(auth);
  const { currentWorkspaceId } = useCurrentUserContext({
    externalId: user?.uid || "",
  });

  let afterDateInDaysValue: number | string | null = afterDateInDays;

  const TWO_YEARS = 365 * 2;
  if (afterDateInDaysValue === "all") {
    afterDateInDaysValue = TWO_YEARS; // 2 years before meetmore === All time
  } else if (!isNaN(Number(afterDateInDaysValue))) {
    afterDateInDaysValue = Number(afterDateInDaysValue);
  } else {
    afterDateInDaysValue = 1;
  }

  if (afterDateInDaysValue >= TWO_YEARS) {
    afterDateInDaysValue = TWO_YEARS;
  }

  const afterDate = dayjs().subtract(afterDateInDaysValue, "day").format("YYYY-MM-DDTHH:mm");

  const { data } = useDashboardAnalyticsData({
    workspaceIds: currentWorkspaceId ? [currentWorkspaceId] : [],
    afterDate,
  });

  useEffect(() => {
    if (!searchParams.get(QUERY_PARAM_DAYS_AGO)) {
      searchParams.set(QUERY_PARAM_DAYS_AGO, "1");
    }
    setSearchParams(searchParams);
  }, []);

  const pipeLineGeneratedInK = data?.pipelineGenerated ? Number(data.pipelineGenerated) / 1000 : 0;
  const pipeLineGeneratedInKAsInteger = Math.floor(pipeLineGeneratedInK);
  const dataToDisplay = [
    {
      name: "Pipeline Generated",
      value: `£${pipeLineGeneratedInKAsInteger}K`,
      summary: `${data?.numberOfConversions || 0} Conversations`,
    },
    {
      name: "Opportunities Progressed",
      value: `${data?.opportunitiesProgressedPercentage.toFixed(1)}%`,
      summary: `${data?.numberOfProgressed || 0} Progressed, ${data?.numberOfLost || 0} Lost, ${
        data?.numberOfActive || 0
      } Active`,
    },
    {
      name: "Your Performance",
      value: `${data?.agentPerformancePercentage.toFixed(1)}%`,
      summary: `${data?.instructionWon || 0} Instruction Won, ${data?.instructionLost || 0} Lost, ${
        data?.numberOfActive || 0
      } Active`,
    },
  ];
  return (
    <>
      <Header />
      <section className="my-8">
        <div className="space-y-12">
          <dl className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
            {dataToDisplay.map(item => (
              <Card key={item.name} className="p-0">
                <div className="px-4 py-4">
                  <dd className="flex items-start justify-between space-x-2">
                    <span className="truncate text-tremor-default text-tremor-content dark:text-dark-tremor-content">
                      {item.name}
                    </span>
                  </dd>
                  <dd className="mt-1 text-tremor-metric font-semibold text-tremor-content-strong dark:text-dark-tremor-content-strong">
                    {item.value}
                  </dd>
                </div>
                <div className="flex justify-start border-t border-tremor-border px-4 py-3 dark:border-dark-tremor-border">
                  <p className="whitespace-nowrap text-sm text-gray-600 dark:text-gray-400">
                    {item.summary}
                  </p>
                </div>
              </Card>
            ))}
          </dl>
        </div>
      </section>
    </>
  );
};

export default Overview;
