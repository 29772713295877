import { useSearchParams } from "react-router-dom";

export type UsePaginationOptions<TData> = {
  pageSize: number;
  totalRecords: number;
};

export type UsePagination = ReturnType<typeof usePagination>;

export const usePagination = <TData>({ pageSize, totalRecords }: UsePaginationOptions<TData>) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const offset = Number(searchParams.get("offset")) || 0;

  const currentPage = Math.floor(offset / pageSize);
  const totalPages = Math.ceil(totalRecords / pageSize);

  const firstRowIndex = offset + 1;
  const lastRowIndex = Math.min(totalRecords, offset + pageSize);

  const setOffset = (offset: number) => {
    setSearchParams({ offset: String(offset) });
  };

  return {
    setOffset,
    firstRowIndex,
    lastRowIndex,
    totalPages,
    currentPage,
    offset,
  };
};
